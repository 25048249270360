<template>
  <!-- <div class="privacy">
    <div class="container"> -->
  <!-- <div class="title listItemTitle"></div> -->
  <div class="content" v-html="$t('about.privacyPolicy.content')"></div>
  <!-- </div>
  </div> -->
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
// .privacy {
//   padding: 10px 0 100px;
//   .title {
//     padding: 17px 0;
//     border-bottom: 1px solid var(--borderColor);
//   }
//   .content {
//     padding-top: 54px;
//   }
// }
</style>
